<template>
  <div class="desktop-test">
    <apps_card
      :background-img="bg"
      :icon="terminusos2Icon"
      :title="$t('desktop_title')"
      :sub-title="subTitle"
      :href="link.desktop_learn_more"
    >
      <object type="image/svg+xml" :data="desktopBG11" width="100%" ></object>
    </apps_card>
  </div>
</template>

<script setup>
import desktopBG11 from "../../assets/new/desktop-bg-1.svg";
import apps_card from "./apps_card.vue";
import terminusos2Icon from "../../assets/terminusos-icon-1.png";
import bg from "../../assets/mobile2/terminusos-bg-1.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const subTitle = [
  t("desktop_subtitle_1"),
  t("desktop_subtitle_2"),
  t("desktop_subtitle_3"),
];
</script>

<style scoped>
.desktop-img-2 {
  width: calc(100% + 24px);
  transform: translateX(-12px);
}
</style>
