import { createI18n } from 'vue-i18n'
import en from './en'

const message = {
  en
}

const i18n = createI18n({
  locale: 'en',
  legacy: false,
  globalInjection: true,
  messages: message,
})

export default i18n;