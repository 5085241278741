<template>
  <div class="page2">
    <div>
      <Card>
        <template #title>
          <span v-html="$t('page2_title')"></span>
        </template>
        <template #sub-title>
          <div class="dsc">
            <span v-html="$t('page2_subtitle')"></span>
          </div>
        </template>
      </Card>
      <div class="flex-row cards-container">
        <div class="card-1-container">
          <div
            class="card-wrapper-1 card-1"
            @mouseenter="mouseenterHander"
            @mouseleave="mouseleaveHandler"
          >
            <div class="card-title">{{ t("page2_title_1") }}</div>
            <div class="card-subtitle">{{ $t("page2_subtitle_1") }}</div>
            <div class="card-dsc">
              <div class="card-dsc-text" v-html="$t('page2_dsc_1')"></div>
            </div>
            <div class="lottie-wrapper">
              <div class="lottie-item">
                <div ref="lottieRef1" class="page2-lottie page2-lottie1"></div>
              </div>
              <div class="lottie-item">
                <div ref="lottieRef2" class="page2-lottie2"></div>
              </div>
            </div>
          </div>
          <div class="arrow-img">
            <img class="img" src="../../assets/arrow.svg" alt="" />
          </div>
        </div>

        <div
          class="card-wrapper-2 card-2"
          @mouseenter="mouseenterHander2"
          @mouseleave="mouseleaveHandler2"
        >
          <div class="card-title">
            {{ $t("page2_title_2") }}
          </div>
          <div class="card-subtitle">{{ $t("page2_subtitle_2") }}</div>
          <div class="card-dsc">
            <div class="card-dsc-text" v-html="$t('page2_dsc_2')"></div>
          </div>
          <div class="lottie-wrapper lottie-wrapper2">
            <div class="lottie-item">
              <div ref="lottieRef3" class="page2-lottie page2-lottie3"></div>
            </div>
            <div class="lottie-item">
              <div ref="lottieRef4" class="page2-lottie page2-lottie4"></div>
            </div>
            <div class="lottie-item">
              <div ref="lottieRef5" class="page2-lottie page2-lottie5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import lottie from "lottie-web";
import Card from "../common/Card.vue";
import { onMounted } from "vue";
import userData from "@/assets/lottie/user.json";
import serverData from "@/assets/lottie/server.json";
import blockchainData from "@/assets/lottie/blockchain.json";
import cloudData from "@/assets/lottie/cloud.json";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const lottieRef1 = ref();
const lottieRef2 = ref();
const lottieRef3 = ref();
const lottieRef4 = ref();
const lottieRef5 = ref();

let lottie1 = undefined;
let lottie2 = undefined;
let lottie3 = undefined;
let lottie4 = undefined;
let lottie5 = undefined;

const mouseenterHander = () => {
  lottie1.play();
  lottie2.play();
};

const mouseleaveHandler = () => {
  lottie1.stop();
  lottie2.stop();
};

const mouseenterHander2 = () => {
  lottie3.play();
  lottie4.play();
  lottie5.play();
};

const mouseleaveHandler2 = () => {
  lottie3.stop();
  lottie4.stop();
  lottie5.stop();
};

const lottieCreate = (container, animationData) => {
  const params = {
    container,
    renderer: "svg",
    loop: true,
    autoplay: false,
    animationData,
  };

  return lottie.loadAnimation(params);
};

onMounted(() => {
  lottie1 = lottieCreate(lottieRef1.value, userData);
  lottie2 = lottieCreate(lottieRef2.value, serverData);
  lottie3 = lottieCreate(lottieRef3.value, userData);
  lottie4 = lottieCreate(lottieRef4.value, cloudData);
  lottie5 = lottieCreate(lottieRef5.value, blockchainData);
});
</script>

<style scoped>
.page2 {
  width: 100%;
}

.dsc {
  color: #706d79;
  text-align: center;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.cards-container {
  margin-top: 61PX;
  display: flex;
  justify-content: space-between;
}
.card-wrapper-1 {
  padding: 40PX 32PX;
  border-radius: 30PX;
  background: linear-gradient(
    180deg,
    #fff 47.87%,
    rgba(255, 255, 255, 0) 77.09%
  );
  background: url(../../assets/v2/grid-1.svg) no-repeat;
  background-position: bottom;
  background-size: contain;
}
.card-wrapper-2 {
  padding: 40PX 32PX;
  border-radius: 30PX;
  background: linear-gradient(
    180deg,
    #fff 47.87%,
    rgba(255, 255, 255, 0) 77.09%
  );
  background: url(../../assets/v2/grid-2.svg) no-repeat;
  background-position: bottom;
  background-size: contain;
}
.card-1 {
  width: 515PX;
  height: 442PX;
}
.card-1-container {
  display: flex;
  align-items: center;
}

.card-2 {
  width: 638PX;
  height: 442PX;
  flex-shrink: 0;
}

.card-title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 24PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card-subtitle {
  margin-top: 16PX;
  margin-bottom: 10PX;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18PX;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.flex-row {
  display: flex;
}

.card-dsc {
  color: #706d79;
  text-align: center;
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  display: flex;
  justify-content: center;
}
.card-dsc-text {
  width: 413PX;
  white-space: break-sapce;
  text-align: center;
}
.arrow-img {
  width: 119.271PX;
  height: 156PX;
  margin-left: -6PX;
}
.lottie-wrapper {
  display: flex;
  justify-content: space-between;

  background: gold;
  position: relative;
}
.lottie-wrapper2 {
}
.lottie-item {
  width: 0;
  height: 0;
}
.page2-lottie {
  width: 144PX;
}
.page2-lottie1 {
  margin-top: 23PX;
  margin-left: 16PX;
}

.page2-lottie2 {
  width: 170PX;
  margin-left: -178PX;
  margin-top: 16PX;
}
.page2-lottie3 {
  margin-left: 15PX;
  margin-top: 30PX;
}

.page2-lottie4 {
  margin-left: -69PX;
  margin-top: 32PX;
}

.page2-lottie5 {
  margin-left: -156PX;
  margin-top: 30PX;
}
</style>
