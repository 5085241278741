<template>
  <div class="page15">
    <Card :title="$t('page15_title')">
      <template #sub-title>
        <div class="page15-subtitle" v-html="$t('page15_subtitle')"></div>
      </template>
    </Card>
    <Carousel class="page15-content">
      <SwiperSlide v-for="(item, index) in data" :key="index">
        <page15_card :img="item.img" :data="item.data" :lazy="item.layz"></page15_card>
      </SwiperSlide>
    </Carousel>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import Carousel from "../common/Carousel.vue";
import { SwiperSlide } from "swiper/vue";
import page15_card from "./page15_card.vue";
import icon1 from "../../assets/v2/protocol-icon-1.svg";
import icon2 from "../../assets/v2/protocol-icon-2.svg";
import icon3 from "../../assets/v2/protocol-icon-3.svg";
import link from "@/common/link";
import img1 from '../../assets/v2/protocol-1.svg'
import img2 from '../../assets/v2/protocol-2.svg'
import img3 from '../../assets/v2/protocol-3.svg'
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const data = [
  {
    img: img1,
    lazy: true,
    data: {
      title: t("new_paradigm_recommendation"),
      link: link.distribution_protocol_learn_more,
      icon: icon1,
      data: [
        {
          title: t("new_paradigm"),
          subtitle: t("new_paradigm_dsc"),
        },
        {
          title: t("open"),
          subtitle: t("open_dsc"),
        },
      ],
    },
  },
  {
    img: img2,
    data: {
      title: t("local_recommend_framework"),
      link: link.distribution_protocol_learn_more,
      icon: icon2,
      data: [
        {
          title: t("privacy_preserving"),
          subtitle: t("privacy_preserving_dsc"),
        },
        {
          title: t("composable_workflow"),
          subtitle: t("composable_workflow_dsc"),
        },
      ],
    },
  },
  {
    img: img3,
    data: {
      title: t("model_enhancement_network"),
      icon: icon3,
      link: link.distribution_protocol_learn_more,
      data: [
        {
          title: t("federated_learning"),
          subtitle: t("federated_learning_dsc"),
        },
        {
          title: t("POI"),
          subtitle: t("POI_dsc"),
        },
      ],
    },
  },
];
</script>

<style scoped>
.page15 {
  width: 100%;
}
.page15-content {
  margin-top: 16PX;
}
.page15-subtitle {
  width: 830PX;
}
</style>
