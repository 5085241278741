<template>
  <div class="page6">
    <Card :title="$t('page6_title')" :sub-title="$t('page6_subtitle')"></Card>
    <div class="page6-content"></div>
    <Carousel>
      <SwiperSlide v-for="(item, index) in data" :key="index">
        <page6_card :img="item.img" :data="item.data"></page6_card>
      </SwiperSlide>
    </Carousel>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import Carousel from "../common/Carousel.vue";
import page6_card from "./page6_card.vue";
import { SwiperSlide } from "swiper/vue";
import img1 from "../../assets/v2/frp-1.svg";
import img2 from "../../assets/v2/frp-2.svg";
import img3 from "../../assets/v2/frp-3.svg";
import img4 from "../../assets/v2/frp-4.svg";
import icon1 from '../../assets/v2/safety-1.svg'
import icon2 from '../../assets/v2/safety-2.svg'
import icon3 from '../../assets/v2/safety-3.svg'
import icon4 from '../../assets/v2/safety-4.svg'
import link from "@/common/link";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const data = [
  {
    img: img1,
    data: {
      title: t("network"),
      subtitle: t("network_dsc"),
      link: link.network_learn_more,
      icon: icon1,
      data: [
        {
          title: t("service_grading"),
          subtitle: t("service_grading_dsc"),
        },
        {
          title: t("access_anywhere"),
          subtitle: t("access_anywhere_dsc"),
        },
        {
          title: t("edge_firewall"),
          subtitle: t("edge_firewall_dsc"),
        },
      ],
    },
  },
  {
    img: img2,
    data: {
      title: t("account"),
      subtitle: t("account_dsc"),
      link: link.account_learn_more,
      icon: icon2,
      data: [
        {
          title: t("single_sign_On"),
          subtitle: t("single_sign_On_dsc"),
        },
        {
          title: t("MFA"),
          subtitle: t("MFA_dsc"),
        },
        {
          title: t("developer_friendly"),
          subtitle: t("developer_friendly_dsc"),
        },
      ],
    },
  },
  {
    img: img3,
    data: {
      title: t("application_sandbox"),
      subtitle: t("application_sandbox_dsc"),
      link: link.application_sandbox_learn_more,
      icon: icon3,
      data: [
        {
          title: t("safe_isolation"),
          subtitle: t("safe_isolation_dsc"),
        },
        {
          title: t("resource_limits"),
          subtitle: t("resource_limits_dsc"),
        },
        {
          title: t("cluster_application"),
          subtitle: t("cluster_application_dsc"),
        },
      ],
    },
  },
  {
    img: img4,
    data: {
      title: t("secret_management"),
      subtitle: t("secret_management_dsc"),
      link: link.secret_management_learn_more,
      icon: icon4,
      data: [
        
        {
          title: t("security_shift_left"),
          subtitle: t("security_shift_left_dsc"),
        },
        {
          title: t("integrated"),
          subtitle: t("integrated_dsc"),
        },
        {
          title: t("stay_in_control"),
          subtitle: t("stay_in_control_dec"),
        },
      ],
    },
  },
];
</script>

<style scoped>
.page6 {
  width: 100%;
}
.page6-content {
  margin-top: 32PX;
}
</style>
