<template>
    <div class="page7">
      <Card align="left">
        <template #title>
          <div class="page7-title" v-html="$t('page7_title')"></div>
        </template>
      </Card>
      <div class="page7-content">
        <div class="left">
          <Expand
            :data="list"
            activeClass="page7-expand-active-class"
            v-model="active"
            @change="changeHander"
          ></Expand>
        </div>
        <div class="right">
          <object class="img-1" type="image/svg+xml" :data="developer1" v-show="active === 0" alt="" />
          <object class="img-2" type="image/svg+xml" :data="developer2" v-show="active === 1" alt="" />
          <object class="img-3" type="image/svg+xml" :data="developer3" v-show="active === 2" alt="" />
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import Card from "../common/Card.vue";
import Expand from "../common/Expand.vue";
import developer1 from "../../assets/v2/developer-img-1.svg";
import developer2 from "../../assets/v2/developer-img-2.svg";
import developer3 from "../../assets/v2/developer-img-3.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const list = [
  {
    title: t("market"),
    subTitle: t("page7_market_dsc"),
    link: link.market_learn_more_2,
  },
  {
    title: t("distribute"),
    subTitle: t("distribute_dsc"),
    link: link.distribute_learn_more,
  },
  {
    title: t("develop"),
    subTitle: t("page7_develop_dsc"),
    link: link.develop_learn_more,
  },
];

const active = ref(0);
const changeHander = () => {
  //
};
</script>

<style scoped>
.page7 {
  position: relative;
  width: 100%;
}
.page7-title {
  width: 900PX;
  white-space: break-spaces;
  
}
.page7-content {
  margin-top: 50PX;
  display: flex;
  justify-content: space-between;
}
.right {
  margin-top: -32PX;
  position: relative;
}
.img-1 {
  margin-top: -64PX;
  margin-right: -33PX;
  width: 719PX;
}
.img-2 {
  width: 649PX;
}
.img-3 {
  margin-right: -33PX;
  width: 719PX;
}
</style>
<style>
.page7-expand-active-class {
  height: 196PX !important;
}
</style>
