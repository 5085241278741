<template>
  <div class="page2">
    <div>
      <Card>
        <template #title>
          <span v-html="$t('page2_title')"></span>
        </template>
        <template #sub-title v-if="$t('page2_subtitle')">
          <div class="dsc">
            <span v-html="$t('page2_subtitle')"></span>
          </div>
        </template>
      </Card>
      <div class="flex-row cards-container">
        <div class="card-1-container">
          <div
            class="card-wrapper-1 card-1"
            @mouseenter="mouseenterHander"
            @mouseleave="mouseleaveHandler"
          >
            <div class="card-title">{{ t("page2_title_1") }}</div>
            <div class="card-subtitle">{{ $t("page2_subtitle_1") }}</div>
            <div class="card-dsc">
              <div class="card-dsc-text" v-html="$t('page2_dsc_1')"></div>
            </div>
            <div class="lottie-wrapper">
              <div class="lottie-item">
                <div ref="lottieRef1" class="page2-lottie page2-lottie1"></div>
              </div>
              <div class="lottie-item">
                <div ref="lottieRef2" class="page2-lottie2"></div>
              </div>
            </div>
          </div>
          <div class="arrow-img">
            <img class="img" src="../../assets/mobile2/arrow.svg" alt="" />
          </div>
        </div>
        <div class="card-1-container">
          <div
            class="card-wrapper-2 card-2"
            @mouseenter="mouseenterHander2"
            @mouseleave="mouseleaveHandler2"
          >
            <div class="card-title">
              {{ $t("page2_title_2") }}
            </div>
            <div class="card-subtitle">{{ $t("page2_subtitle_2") }}</div>
            <div class="card-dsc">
              <div class="card-dsc-text" v-html="$t('page2_dsc_2')"></div>
            </div>
            <div class="lottie-wrapper lottie-wrapper2">
              <div class="lottie-item">
                <div
                  ref="lottieRef3"
                  class="page2-lottie-bottom page2-lottie3"
                ></div>
              </div>
              <div class="lottie-item">
                <div
                  ref="lottieRef4"
                  class="page2-lottie-bottom page2-lottie4"
                ></div>
              </div>
              <div class="lottie-item">
                <div
                  ref="lottieRef5"
                  class="page2-lottie-bottom page2-lottie5"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import lottie from "lottie-web";
import Card from "../common/CardMobile.vue";
import { onMounted } from "vue";
import userData from "@/assets/lottie/user.json";
import serverData from "@/assets/lottie/server.json";
import blockchainData from "@/assets/lottie/blockchain.json";
import cloudData from "@/assets/lottie/cloud.json";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const lottieRef1 = ref();
const lottieRef2 = ref();
const lottieRef3 = ref();
const lottieRef4 = ref();
const lottieRef5 = ref();

let lottie1 = undefined;
let lottie2 = undefined;
let lottie3 = undefined;
let lottie4 = undefined;
let lottie5 = undefined;

const mouseenterHander = () => {
  lottie1.play();
  lottie2.play();
};

const mouseleaveHandler = () => {
  lottie1.stop();
  lottie2.stop();
};

const mouseenterHander2 = () => {
  lottie3.play();
  lottie4.play();
  lottie5.play();
};

const mouseleaveHandler2 = () => {
  lottie3.stop();
  lottie4.stop();
  lottie5.stop();
};

const lottieCreate = (container, animationData) => {
  const params = {
    container,
    renderer: "svg",
    loop: true,
    autoplay: false,
    animationData,
  };

  return lottie.loadAnimation(params);
};

onMounted(() => {
  lottie1 = lottieCreate(lottieRef1.value, userData);
  lottie2 = lottieCreate(lottieRef2.value, serverData);
  lottie3 = lottieCreate(lottieRef3.value, userData);
  lottie4 = lottieCreate(lottieRef4.value, cloudData);
  lottie5 = lottieCreate(lottieRef5.value, blockchainData);
});
</script>

<style scoped>
.page2 {
  width: 100%;
}

.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-wrapper-1 {
  padding: 24px 29px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    #fff 47.87%,
    rgba(255, 255, 255, 0) 77.09%
  );
  background: url(../../assets/mobile2/grid-1.svg) no-repeat;
  background-position: bottom;
  background-size: cover;
}
.card-wrapper-2 {
  padding: 24px 29px;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    #fff 47.87%,
    rgba(255, 255, 255, 0) 77.09%
  );
  background: url(../../assets/mobile2/grid-2.svg) no-repeat;
  background-position: bottom;
  background-size: cover;
}
.card-1 {
  width: 343px;
  height: 294px;
}
.card-1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-2 {
  width: 343px;
  height: 284px;
}

.card-title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.card-subtitle {
  margin-top: 8px;
  margin-bottom: 10px;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.flex-row {
  display: flex;
}

.card-dsc {
  color: #706d79;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px; /* 133.333% */

  display: flex;
  justify-content: center;
}
.card-dsc-text {
  width: 316px;
  white-space: break-sapce;
  text-align: center;
}
.arrow-img {
  font-size: 0;
  margin-top: -3px;
}
.arrow-img img {
  width: 62px;
}
.lottie-wrapper {
  display: flex;
  justify-content: space-between;

  background: gold;
  position: relative;
}
.lottie-item {
  width: 0;
  height: 0;
}
.page2-lottie {
  width: 96px;
}
.page2-lottie1 {
  margin-top: -6px;
  margin-left: 5px;
}

.page2-lottie2 {
  width: 108px;
  margin-left: -111px;
  margin-top: -5px;
}
.page2-lottie-bottom {
  width: 80px;
}
.page2-lottie3 {
  margin-top: 31px;
  margin-left: 2px;
}

.page2-lottie4 {
  margin-left: -41px;
  margin-top: 30px;
}

.page2-lottie5 {
  margin-left: -80px;
  margin-top: 32px;
}
</style>
<style>
.page1-title-highlight {
  background: linear-gradient(90deg, #fb7d47 71.92%, #ff6e6e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.04px;
}
.page1-dsc-item {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
