<template>
  <div
    class="card-container card-container-layout"
    :style="{
      alignItems: align === 'left' ? 'flex-start' : 'center',
      textAlign: align,
    }"
  >
    <div class="title">
      {{ props.title }}
      <slot name="title"></slot>
    </div>
    <div
      class="sub-title"
      :class="{ margin_top: props.subTitle || $slots['sub-title'] }"
    >
      {{ props.subTitle }}
      <slot name="sub-title"></slot>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  title: String,
  subTitle: String,
  align: String,
});

const align = ref(props.align || "center");
</script>

<style scoped>
.card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-container-layout {
  background: var(
    --www_todesktop_com__1440x810_default-Linear-Whisper-Whisper,
    linear-gradient(135deg, rgba(250, 250, 252, 0.7) 0%, #fafafc 100%)
  );
  border: 1PX solid #ddd;
  padding: 32PX;
  border-radius: 16PX;
}
.title {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}
.sub-title {
  color: #6D6D6D;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
.margin_top {
  margin-top: 12PX;
}
</style>
