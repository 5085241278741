<template>
  <div class="page7_wrap">
    <div class="page7">
      <Card align="left">
        <template #title>
          <div class="page7-title" v-html="$t('page7_title')"></div>
        </template>
      </Card>
      <div class="page7-content-wrapper">
        <div v-for="(item, index) in list" :key="index" class="page7-content">
          <div class="item-title">{{ item.title }}</div>
          <object class="img-1" type="image/svg+xml" :data="item.img" ></object>
          <div class="item-subtitle" :class="{'item-subtitle-2': index === 1}">{{ item.subTitle }}</div>
          <a :href="item.link" class="learn-more" target="_blank">{{
            $t("learn_more")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from "../common/CardMobile.vue";
import developer1 from "../../assets/v2/developer-img-1.svg";
import developer2 from "../../assets/v2/developer-img-2.svg";
import developer3 from "../../assets/v2/developer-img-3.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const list = [
  {
    title: t("market"),
    subTitle: t("page7_market_dsc"),
    link: link.market_learn_more_2,
    img: developer1,
    lazy: true
  },
  {
    title: t("distribute"),
    subTitle: t("distribute_dsc"),
    link: link.distribute_learn_more,
    img: developer2,
  },
  {
    title: t("develop"),
    subTitle: t("page7_develop_dsc"),
    link: link.develop_learn_more,
    img: developer3,
  },
];
</script>

<style scoped>
.page7-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
  white-space: break-spaces;
}
.page7-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.page7-content {
  padding: 32px 27px;
  border-radius: 12px;
  background: var(--horizon-ui-com-nero, #fff);
  box-shadow: 9.604px 11.662px 27.44px 2.744px rgba(112, 144, 176, 0.08);
}
.right {
  margin-top: -32px;
  position: relative;
}
.item-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}
.item-subtitle {
  color: #706d79;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.item-subtitle-2 {
  margin-top: 24px;
}
.img-1 {
  width: 100%;
  margin-top: 20px;
}
.learn-more {
  color: #706d79;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 20px;
}
</style>
