<template>
    <apps_card_mini
      :background-img="item.bg"
      :icon="item.icon"
      :title="item.title"
      :sub-title="item.subTitle"
      v-for="(item, index) in data"
      :key="index"
      :href="item.link"
      class="apps-mini-wrapper"
    >
        <object type="image/svg+xml" :data="item.img" :class="item.classname" ></object>
    </apps_card_mini>

</template>

<script setup>
import desktopBG1 from "../../assets/mobile2/desktop-bg-6_1.svg";
import desktopBG2 from "../../assets/mobile2/desktop-bg-6_2.svg";
import desktopBG3 from "../../assets/mobile2/desktop-bg-6_3.svg";

import apps_card_mini from "./apps_card_mini.vue";
import icon1 from "../../assets/terminusos-icon-6_1.png";
import bg1 from "../../assets/mobile2/terminusos-bg-6_1.svg";
import icon2 from "../../assets/terminusos-icon-6_2.png";
import bg2 from "../../assets/mobile2/terminusos-bg-6_2.svg";
import icon3 from "../../assets/terminusos-icon-6_3.png";
import bg3 from "../../assets/mobile2/terminusos-bg-6_3.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const data = [
  {
    title: t("settings"),
    subTitle: t("settings_subtitle"),
    icon: icon1,
    link: link.settings_learn_more,
    bg: bg1,
    img: desktopBG1,
    classname: "img-1",
  },
  {
    title: t("dashboard"),
    subTitle: t("dashboard_title"),
    icon: icon2,
    link: link.dashboard_learn_more,
    bg: bg2,
    img: desktopBG2,
    classname: "img-2",
  },
  {
    title: t("profile"),
    subTitle: t("profile_subtitle"),
    icon: icon3,
    link: link.profile_learn_more,
    bg: bg3,
    img: desktopBG3,
    classname: "img-3",
  },
];
</script>

<style scoped>
.apps-mini-wrapper {
  flex: 1;
}

.img-1 {
  width: calc(100% + 21px);
  transform: translateX(-7px);
  transform: translateY(18px);
}
.img-2 {
  width: 100%;
  margin-top: 12px;
  position: relative;
}
.img-3 {
  width: 100%;
  transform: translateY(18px);
}
</style>
