<template>
  <div class="nav_wrap" :class="[moreScreenHeight ? 'header_light' : 'header_dark', !moreScreenHeight2 ? 'header_dark2' : '', slideUp ? 'header_transparent': '']">
    <div class="nav">
      <div class="nav_loge" @click="goHome">
        <div class="logo">
          <img src="../../assets/mobile2/logo.png" alt="logo">
        </div>
        <div class="nav_logo_text">
          <span :class="slideUp ? 'nav_loge_ani_hide' : 'nav_loge_ani_show'" class="logo-text">
            <img v-if="moreScreenHeight" src="../../assets/v2/logo-text-black.svg" alt="">
            <img v-else src="../../assets/v2/logo-text.svg" alt="">
          </span>
        </div>
      </div>

      <div class="nav_right">
        <div class="nav_github_text" @click="goMenu(100)">
          <div class="get_start" :class="slideUp ? 'nav_github_ani_hide' : 'nav_github_ani_show'">
            {{ t('get_started') }}
          </div>
        </div>

        <div class="nav_list_menu" @click="clickDownMenu">
          <img v-if="!moreScreenHeight" src="../../assets/mobile2/menu.svg" alt="">
          <img v-else src="../../assets/mobile2/menu-dark.svg" alt="">
        </div>
      </div>
    </div>
    <DownMenu ref="childsDom" :NavList="NavList" @goMenu="goMenu" />
  </div>
</template>


<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import DownMenu from './drop_down_menu.vue';

const router =  useRouter();
const {t} = useI18n();
let NavList = ref([
  {
    name: t('home'),
    id: 0
  },
  {
    name: t('docs'),
    id: 1
  },
  {
    name: t('market'),
    id: 2
  },
  {
    name: t('space'),
    id: 3
  },
]);
let page18height = 0
const activeNav = ref(0);
const slideUp = ref(false);
const moreScreenHeight = ref(false);
const moreScreenHeight2 = ref(false);
const starting = ref(0);

const childsDom = ref(null);

const clickDownMenu = () => {
  childsDom.value.handelClick();
};

const windowScroll = () => {
  const screenHeight = document.documentElement.clientHeight;
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;


  if (scrollTop > screenHeight - 40) {
    moreScreenHeight.value = true;
  } else {
    moreScreenHeight.value = false;
  }

  if(screenHeight - scrollTop < 80) {
    moreScreenHeight2.value = true
  }else {
    moreScreenHeight2.value = false
  }
  let scroll = scrollTop - starting.value;
  starting.value = scrollTop;
  if (scroll > 0 && scrollTop > 0) {
    slideUp.value = true;
  } else {
    slideUp.value = false;
  }

  if ((page18height + window.scrollY) >= document.body.offsetHeight || scrollTop < screenHeight) {
        moreScreenHeight.value = false;
    }
}

const goHome = () => {
  router.push("/")
}

const goMenu = (value) => {
  activeNav.value = value;
  if (value === 1) {
    window.open('https://docs.jointerminus.com/');
  } else if( value == 2 ) {
    window.open('https://market.jointerminus.com/');
  } else if( value == 3 ) {
    window.open('https://space.jointerminus.com/');
  } else if( value == 100 ) {
    window.open('https://github.com/beclab')
  } else if( value == 200 ) {
    window.open('https://docs.jointerminus.com/overview/introduction/getting-started.html')
  }
}

onMounted(() => {
  page18height = document.querySelector('.page18-mobile').offsetHeight
  window.addEventListener('scroll', windowScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', windowScroll);
})

</script>

<style scoped>
.nav_wrap {
  width: 100%;
  max-width: 100%;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header_light {
  background: #FFFFFF;
}
.header_light .nav_list_li {
  color: #232323;
}

.nav_loge {
  display: flex;
  align-items: center;
  height: 21px;
}

.header_light .nav_loge span {
  color: #232323;
}

.header_light .get_start {
  background-color: #FFFFFF;
  color: #232323;
}



.header_dark2 {
  background: #09090B;
}

.header_dark .nav_list_li {
  color: #FFFFFF;
}

.header_dark .nav_loge span {
  color: #FFFFFF;
}

/* .header_dark .nav_loge .logo {
  background-color: #09090B;
} */

.header_dark .get_start {
  color: #FFFFFF;
}
.header_transparent {
  background: transparent;
}
.nav {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_shadow {
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 1px 10px #e5e5e5;
}

.nav_loge {
  width: 84px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.nav_loge .logo {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 0;
}

.nav_loge .logo img {
  width: 20px;
  height: 20px;
} 

.nav_logo_text {
  width: 58px;
  height: 12px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.nav_logo_text  img {
  width: 100%;
}

.logo-text {
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
}

.nav_loge_ani_hide {
  animation: logoHideAni .4s ease-in-out forwards;
}
@keyframes logoHideAni {
  0% {
    right: 0;
  }
  100% { 
    right: 150PX;
  }
}
.nav_loge_ani_show {
  animation: logoShowAni .4s ease-in-out forwards;
}

@keyframes logoShowAni {
  0% {
    right: 150PX;
  }
  100% { 
    right: 0;
  }
}

.nav_list_wrap {
  position: relative;
}

.nav_list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav_list_ani_hide {
  animation: navAniHide .4s ease-in-out forwards;
}

@keyframes navAniHide {
  0% {
    top: 0;
  }
  100% { 
    top: -98PX;
  }
}

.nav_list_ani_show {
  animation: navAniShow .4s ease-in-out forwards;
}

@keyframes navAniShow {
  0% {
    top: -98PX;
  }
  100% { 
    top: 0;
  }
}


.nav_list_li {
  margin: 0 27PX;
  font-size: 14PX;
  cursor: pointer;
  height: 30PX;
  line-height: 30PX;
}
.active_nav {
  color: rgba(255, 91, 40, 1) !important;
}

.nav_right {
  position: relative;
  width: 110px;
  height: 32px;
}

.nav_github_text {
  width: 82px;
  height: 27px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
}

.nav_github_ani_hide {
  animation: githubHideAni .4s ease-in-out forwards;
}
@keyframes githubHideAni {
  0% {
    left: 0;
  }
  100% { 
    left: 110px;
  }
}
.nav_github_ani_show {
  animation: githubShowAni .4s ease-in-out forwards;
}

@keyframes githubShowAni {
  0% {
    left: 110px;
  }
  100% {
    left: 0;
  }
}

.get_start {
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  background: url("../../assets/v2/btn-border.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav_list_btn {
  box-sizing: border-box;
  width: 96PX;
  height: 32PX;
  font-size: 12PX;
  line-height: 32PX;
  text-align: center;
  color: #FFFFFF;
  background: #202020;
  border: 1px solid #000000;
  border-radius: 16PX;
  margin-right: 20PX;
}

.nav_list_menu {
  width: 20PX;
  height: 20PX;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>