import link from '../common/link'

const en = {
  home: "Home",
  docs: "Docs",
  market: "Market",
  space: "Space",
  get_started: "Get Started",
  page1_title: "What is Terminus?",
  terminus_dsc_1: "Terminus is",

  terminus_dsc_1_1: "a gateway to",
  terminus_dsc_1_2: "decentralized internet",


  terminus_dsc_2_1: "an all-in-one self-hosted",
  terminus_dsc_2_2: "solution",
  

  terminus_dsc_3_1: "a journey toward",
  terminus_dsc_3_2: "data sovereignty",

  terminus_dsc_4_1: "a productivity platform for",
  terminus_dsc_4_2: "team collaboration",

  terminus_dsc_5_1: "a light cloud running",
  terminus_dsc_5_2: "at your home",

  terminus_dsc_6_1: "a witness to a lifetime of",
  terminus_dsc_6_2: "memories",

  terminus_dsc_7_1: "a container to run your",
  terminus_dsc_7_2: "personal intelligent assistant",

  terminus_dsc_8_1: "an attempt to break out of ",
  terminus_dsc_8_2: "information bubble",

  terminus_dsc_9_1: "an attempt to give a fair price to",
  terminus_dsc_9_2: "public goods",


  terminus_dsc_10_1: "a free, source-available OS ",
  terminus_dsc_10_2: "based on Kubernetes",


  page2_title:
    'The Evolution of the <span class="page1-title-highlight">Internet</span>',
  // page2_subtitle: `<a class="link-flash" href="${link.bec_introduction}" target="_blank">BEC Architecture</a> consists of three parts:
  //   <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Snowinning Protocol</a>, a smart contract
  //   running on blockchain. <a class="link-flash" href="${link.terminus_OS}" target="_blank">Terminus OS</a>, an
  //   operating system running on the personal edge server.
  //   <a class="link-flash" href="${link.termiPass}" target="_blank">TermiPass</a>, a client software running
  //   on the user's mobile phone and computer.`,
  // page2_subtitle: `<b>Blockchain Edge Client (BEC) Architecture</b>:
  // <br><b>Snowinning Protocol:</b> An identity and reputation protocol based on blockchain smart contracts.
  // <br><b>Terminus OS:</b> An operating system running on personal edge servers.
  // <br><b>TermiPass:</b> Client application that runs on users' phones and computers.`,
  page2_subtitle: "",
  page2_title_1: "Centralized Internet",
  page2_subtitle_1: "Client-Server Architecture",
  page2_dsc_1: `Users lack control over data, identity, and secrets. Companies manage accounts and store data on their servers.`,
  page2_title_2: "Internet of Data Sovereignty ",
  page2_subtitle_2: "Blockchain Edge Client Architecture",
  page2_dsc_2: `Applications and data reside at the edge. Secrets are stored on clients, identities on blockchain. <a class="link-flash link-flash-wrapper" href="${link.bec_introduction}" target="_blank">Learn More</a>`,
  page3_title: "Snowinning Protocol",
  // page3_subtitle: `By integrating Decentralized Identifiers, Verifiable Credentials and
  //   Decentralized Reputation into the blockchain smart contract.
  //   <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Snowinning Protocol</a> has created a decentralized identity and reputation system.`,
  page3_subtitle: `A decentralized identity and reputation system that integrates decentralized identifiers (DIDs), verifiable credentials (VCs), and reputation data into blockchain smart contracts. <a class="link-flash" href="${link.snowinning_protocol}" target="_blank">Learn More</a>`,
  desktop: "Desktop",
  files: "Files",
  vault: "Vault",
  wise: "Wise",
  manage: "Manage",
  develop: "Develop",
  page4_title: "Terminus OS",
  page4_subtitle: "Terminus comes with a rich set of built-in apps, making it as easy to use as your smartphone.",
  read_more: "Learn More",
  desktop_title: "AI-Powered Personal Desktop",
  desktop_subtitle_1:
    "<span class='page4-bolder'>Built-in Launcher:</span> Launch apps, consult AI, and complete tasks effortlessly.",
  desktop_subtitle_2:
    "<span class='page4-bolder'>Extensive Customization:</span> Make your desktop uniquely yours.",
  desktop_subtitle_3:
    "<span class='page4-bolder'>Familiar Interface:</span> Jump right in with an intuitive, desktop-like experience.",
  files_title: "A Secure Home to Your Data ",
  files_subtitle_1:
    "<span class='page4-bolder'>Unified Access:</span> View and share all your files stored in Terminus from any device or web browser.",
  files_subtitle_2:
    "<span class='page4-bolder'>Seamless Syncing:</span> Keep your files synced and accessible across all your devices.",
  files_subtitle_3:
    "<span class='page4-bolder'>Centralized Storage:</span> Consolidate valuable data scattered across the web back into your Terminus.",
  vault_title: "1Password for the Web3 Era",
  vault_subtitle_1:
    "<span class='page4-bolder'>Free & Secure:</span> Enjoy cryptography feature similar to 1Password, completely free.",
  vault_subtitle_2:
    "<span class='page4-bolder'>DID & VC Management:</span> Effectively manage your DIDs and VCs, and communicate with global issuers and verifiers.",
  vault_subtitle_3:
    "<span class='page4-bolder'>Multi-Chain Wallet (Coming Soon):</span> Securely manage your assets across multiple blockchains.",
  market_title: "App Ecosystem in Your Control",
  market_subtitle_1:
    "<span class='page4-bolder'>Permissionless App Ecosystem:</span> Provides a permissionless app ecosystem through sandboxing.",
  market_subtitle_2:
    "<span class='page4-bolder'>Unified Account:</span> Seamless application access with a single account for all apps (app-specific implementation may vary).",
  market_subtitle_3:
    "<span class='page4-bolder'>Effortless Deployment:</span> One-click installation with zero configuration and maintenance.",
  wise_title: "Your Digital Secret Garden",
  wise_subtitle_1:
    "<span class='page4-bolder'>AI-Powered, Local-First Reader:</span> Easily collect, read, and manage information from various platforms.",
  wise_subtitle_2:
    "<span class='page4-bolder'>Tailored Recommendations:</span> Break out of filter bubbles with tailored content powered by self-hosted recommendation algoritms.",
  wise_subtitle_3: "<span class='page4-bolder'>Third-Party Integrations:</span>  Provide APIs for smooth integration with 3rd-party knowledge apps.",
  settings: "Settings",
  settings_subtitle: "Manage your Terminus as easily as your computer.",
  dashboard: "Dashboard",
  dashboard_title: "Constantly monitor the operation of Terminus.",
  profile: "Profile",
  profile_subtitle: "Customize your Web3 homepage.",
  devbox: "Devbox",
  devbox_subtitle:
    "Comprehensive developer tools for developing, debugging, and deploying apps on Terminus.",
  control_hub: "ControlHub",
  control_hub_subtitle:
    "Manage Kubernetes clusters without needing to memorize commands.",
  page5_title: "Your Light Cloud, at Home",
  page5_subtitle:
    "Terminus leverages Kubernetes to bring a decade of open-source and cloud-native advancements to your home.",
  page5_dsc_1: "cost reduction compared to using cloud",
  page5_dsc_2: "cloud native development experience",
  page5_dsc_3: "hybrid deployment on-premises and on the cloud",
  unlimited: "Unlimited",
  compute: "Compute",
  storage: "Storage",
  middleware: "Middleware",
  page6_title: "Security Above All",
  page6_subtitle:
    "Terminus comes with an enterprise-grade security solution that works out of the box.",
  network: "Network",
  network_dsc:
    "From domain names to security software, Terminus handles all network-related configurations.",
  service_grading: "Service Grading",
  service_grading_dsc:
    "Developers and users can flexibly set security policies for different services.",
  access_anywhere: "Access Anywhere",
  access_anywhere_dsc:
    "Securely and quickly access private services on Terminus from anywhere in the world through Tailscale.",
  edge_firewall: "Edge Firewall",
  edge_firewall_dsc:
    "By hiding public services behind Cloudflare, Terminus can achieve enterprise-grade security protection.",
  account: "Account",
  account_dsc:
    "Applications can integrate their accounts with Terminus to share identity authentication services.",
  single_sign_On: "Unified Account",
  single_sign_On_dsc:
    "Seamlessly access all Terminus apps with a single login, eliminating the hassle of separate app logins.",
  MFA: "Configurable MFA",
  MFA_dsc:
    "Enhance security for sensitive operations with user-configurable multi-factor authentication (MFA) policies.",
  developer_friendly: "Developer friendly",
  developer_friendly_dsc:
    "Leverage detailed documentation to seamlessly integrate third-party applications with Terminus.",
  application_sandbox: "Application",
  application_sandbox_dsc:
    "Through application sandboxing, we have launched a truly independent third-party app store on Terminus.",
  safe_isolation: "Safe Isolation",
  safe_isolation_dsc:
    "Network, storage, and middleware isolation has been implemented for both the system and third-party applications.",
  resource_limits: "Resource limits",
  resource_limits_dsc:
    "Applications can flexibly define the resources they need. The operating system will promptly terminate programs with abnormal resource usage.",
  cluster_application: "Cluster Application",
  cluster_application_dsc:
    "Users can collaborate through applications that utilize a shared cluster model.  ",
  secret_management: "Secret Management",
  secret_management_dsc:
    "Manage all application credentials and secrets in one place with identity-based security.",
  security_shift_left: "Security Shift Left",
  security_shift_left_dsc:
    'Built-in Infisical provides tools to automate your security processes with a "shift left" approach.',
  integrated: "Easy Integration",
  integrated_dsc:
    "Easily integrate with third-party applications and workflows on Terminus.",
  stay_in_control: "Stay in Control",
  stay_in_control_dec:
      "Set up tight granular permissions for human and machine identities.",
  page7_title: "A Protocol-Based, Permissionless Application Ecosystem",
  page7_market_dsc:
    "Install third-party applications, recommendation algorithms, LLMs, and agents with a single click.",
  distribute: "Distribute",
  distribute_dsc: `Distribute Terminus apps through a permissionless application distribution protocol.`,
  page7_develop_dsc:
    "Use DevBox to develop and debug Terminus applications.",
  page8_title: "Self-Host Your Productivity Tools for Team Collaboration",
  page8_dsc:
    "Chat, Meeting, and Calendar will be available soon.",
  page9_title: "Witness the Memories of Your Life",
  page9_subtitle:
    "Help you store data scattered around and make it easier to use with AI.",
  sync: "Sync",
  sync_dsc:
    "Automatically sync valuable documents, photos, videos, and chat records scattered across various places to Terminus.",
  browser: "Browse",
  browser_dsc:
    "View, edit, and manage data from different channels using built-in or third-party apps, consolidating it into knowledge in Terminus.",
  ask: "Ask",
  ask_dsc:
    "Easily retrieve past memories with intelligent search, leveraging your data to enhance AI agents' capabilities.",
  store: "Store",
  store_dsc:
    "Efficiently and securely store your data in Terminus. Its built-in backup system automatically performs regular backups of your data.",
  page10_title: "Local AI Model <br/>Inference Framework",
  page10_subtitle: `Run the world's best open-source large language, image, vision, and speech models locally, and seamlessly integrate them with your data, applications, and agents.`,
  page11_title: "Redefine Personal AI Assistants",
  page11_subtitle:
    "A local AI assistant that is fully automated, privacy-preserving, and highly customizable, and easy-to-integrate.",
  build_agent: "Build Agents without Coding",
  build_agent_dsc: `Orchestrate agents using self-hosted Dify to integrate local knowledge bases and application tools . Alternatively, you can install pre-configured agents directly from the Market.`,
  seamless_integration_knowledge: "Integrate Local Private Knowledge",
  seamless_integration_knowledge_dsc:
    "As your secondary brain and digital avatar, your agent's responses are based on your local documents, photos, videos, chat records, health records, etc.",
  complete_jobs: "Automate Your Tasks",
  complete_jobs_dsc:
    "Upon receiving authorization, agents can access system data and applications to autonomously complete tasks. For instance, they can book hotels and flights based on travel itineraries.",
  agent_service: "Provide Agent As a Service",
  agent_service_dsc:
    "Using the Otmoic protocol, agents from different users can automate the negotiation, purchase, and sale of AI services and hardware resources.",
  page12_title: "Enjoy Home Entertainment Center Without Limits",
  page12_subtitle:
    "Fulfill all your entertainment needs with a suite of applications available in Terminus.",
  movies_shows: "Movies & Shows",
  game: "Game",
  music: "Music",
  VR_streaming: "VR Streaming",
  movies_shows_title: "Free Media System",
  movies_shows_subtitle:
    "Jellyfin is the volunteer-built media solution that puts you in control of your media. Stream to any device from your own server, with no strings attached. Your media, your server, your way.",
  game_title: "Self-hosted Cloud Gaming Service",
  game_subtitle: `Play your favorite games on any device by connecting to Terminus.`,
  music_title: "Rediscover your music",
  music_subtitle:
    "Navidrome is a sleek, open-source music server designed for easy  streaming of your music library, providing a delightful listening experience across devices. Discover great music anytime, anywhere.",
  VR_streaming_title: "Immerse yourself in your favorite VR & AR games",
  VR_streaming_subtitle:
    "Terminus offers the GPU power needed for VR & AR gaming, waiting for you to unlock its full potential.",
  learn_more: "Learn More",
  page13_title: "Take Control of Your Smart Home",
  page13_subtitle:
    "Manage your smart devices, regardless of brand, through a local and free console without worrying about privacy breaches. Don’t let cloud services control your home.",
  page14_title: "Own Your Social Brand",
  page14_subtitle:
    "By installing applications on Terminus, you gain the freedom to connect directly with your fans without censorship or fees.",
  ghost: "Ghost",
  ghost_title: "Independent technology for modern publishing",
  ghost_subtitle:
    "Ghost is a powerful app for professional publishers to create, share, and grow a business around their content. It comes with modern tools to build a website, publish content, send newsletters & offer paid subscriptions to members.",
  mastodon: "Mastodon",
  mastodon_title: `Social networking that's not for sale`,
  matrix: "Matrix",
  matrix_title: "An open network for secure, decentralized communication",
  page15_title: "Decentralized Information Distribution Protocol",
  page15_subtitle:
    'By establishing a decentralized protocol for information distribution, we can reclaim our "feeds" from the hands of tech giants.',
  new_paradigm_recommendation: "A New Paradigm for Recommendation",
  new_paradigm: "New Paradigm",
  new_paradigm_dsc:
    "By dividing the algorithm into two phases, we have crafted a recommendation engine protocol designed with privacy protection in mind.",
  open: "Open",
  open_dsc:
    "Algorithm providers can submit their algorithms to the market, where users can install any third-party algorithm.",
  local_recommend_framework: "Local Recommend Framework",
  privacy_preserving: "Privacy Preserving",
  privacy_preserving_dsc:
    "Since each Terminus receives the same data from the Cloud and the recommendation algorithm operates in a network-free environment, user feedback data is maintained with confidentiality.",
  composable_workflow: "Composable Workflow",
  composable_workflow_dsc:
    "Developers can freely orchestrate processes such as recommendations and training across different stages of the algorithm.",
  model_enhancement_network: "Model Enhancement Network",
  federated_learning: "Federated Learning",
  federated_learning_dsc:
    "With user consent, Terminus is able to engage in the iterative improvement of algorithms through federated learning and receive token incentives.",
  POI: "POI（Proof of Intelligent）",
  POI_dsc:
    "We aim to design a proof-of-intelligent system that fairly rewards users who provide gradient data for training models.",
  page16_title: "Give Public Goods a Fair Price",
  page16_subtitle:
    `Otmoic Protocol is an automated value exchange network built for DePin, AI Agents and Crypto Trading, without the need for third-party trust. <a class="link-flash" href="${link.otmoic_protocol}" target="_blank">Learn More</a> `,
  reputation: "Reputation",
  reputation_dsc_1:
    "Records reputation history in smart contracts.",
  reputation_dsc_2:
    "Utilize KYC methods based on Verifiable Credentials to understand your counterparties when necessary.",
  RFQ: "RFQ",
  RFQ_dsc:
    "Solving the Free Mint problem by incorporating a confirmation phase.",
  numerous_application_scenarios: "Numerous application scenarios",
  automated_market_maker: "Automated Market Maker",
  automated_market_maker_dsc:
    "Anyone can become a market maker by installing the Otmoic application in Terminus.",
  atomic_swap: "Atomic Swap",
  atomic_swap_dsc:
    "Process on-chain and cross-chain transactions using Atomic Swaps, eliminating the need for a trusted third party.",
  page17_title: "Special thanks to the following open source projects",
  page18_title: "Ready to get your data back?",
};

export default en;
